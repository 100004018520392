import TrackedLink from "./TrackedLink";

const Footer = () => {
  return (
    <div>
      <hr />
      <p>
        For support, please{" "}
        <TrackedLink
          href="http://portal3.orrick.com/PortalApps/Tools/AppsChatRedirect.aspx"
          target="_blank"
          rel="noreferrer"
          trackedLinkName="link to contact Service Desk Chat"
        >
          contact Service Desk Chat
        </TrackedLink>
        .
      </p>
      <p>
        We would love to hear about your experiences using the tool.
        <br /> Please email any feedback to{" "}
        <TrackedLink
          href="mailto:innovation@orrick.com"
          trackedLinkName="link to email feedback to innovation@orrick.com"
        >
          innovation@orrick.com
        </TrackedLink>
        .
      </p>
    </div>
  );
};

export default Footer;
