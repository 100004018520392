import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const StyledAccordion = ({ startExpanded, title, children }) => {
  return (
    <Accordion defaultExpanded={startExpanded}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
        sx={{ background: "#7a9c49", color: "white" }}
      >
        <strong>{title}</strong>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};

export default StyledAccordion;
