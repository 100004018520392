import "./common-style.css";
import TextField from "@mui/material/TextField";
import styled from "styled-components";
import { styled as muiStyled } from "@mui/material/styles";
import { ToolTipIcon } from "./ToolTipIcon";
import { useEffect, useState } from "react";

const Container = styled.div`
  text-align: center;
  vertical-align: text-top;
  height: 56px;
  line-height: 56px;
  vertical-align: middle;
  margin-top: 15px;
  margin-bottom: 35px;
`;

const StyledTextField = muiStyled(TextField)({
  width: "135px",
  margin: "0 15px",
});

const HelpText =
  "Please select a page range to be processed, such that it excludes as much text as possible from the beginning and end that you would not want made into flashcards, e.g. the table of contents and appendices.";

export const PageNumberInput = ({
  label,
  error,
  onChange,
  value,
  min,
  max,
  id,
}) => {
  const helperText = (value, error) =>
    error ? error : value !== "" ? "" : "required";

  return (
    <StyledTextField
      required
      type="number"
      autoComplete="false"
      InputProps={{ inputProps: { min: min ?? 1, max } }}
      helperText={helperText(value, error)}
      onKeyDown={(e) => {
        if (e.key === "-") e.preventDefault();
      }}
      onChange={(e) => {
        let text = e.target.value;
        if (text !== "0" && text !== "-") onChange(e.target.value);
      }}
      error={error !== null}
      {...{
        id,
        label,
        value,
      }}
    />
  );
};

export const PageRangeSelector = ({
  maxPage,
  startPage,
  setStartPage,
  endPage,
  setEndPage,
  setIsValid,
}) => {
  const [startPageError, setStartPageError] = useState(null);
  const [endPageError, setEndPageError] = useState(null);

  useEffect(() => validateRange(startPage, endPage), [startPage, endPage]);

  const onStartPageChange = (text) => {
    setStartPageError(null);
    if (setStartPage) setStartPage(text);
  };

  const onEndPageChange = (text) => {
    if (setEndPage) setEndPage(text);
    setEndPageError(null);
  };

  const validateRange = (startPage, endPage) => {
    if (startPage === "") setStartPageError("required");
    if (endPage === "") setEndPageError("required");
    if (startPage !== "" && endPage !== "") {
      if (parseInt(startPage) <= parseInt(endPage)) {
        setStartPageError(null);
        setEndPageError(null);
        if (setIsValid) setIsValid(true);
      } else {
        setStartPageError("invalid range");
        setEndPageError("invalid range");
        if (setIsValid) setIsValid(false);
      }
    }
  };

  return (
    <Container>
      Select page range:
      <PageNumberInput
        id="fromPage"
        label="Starting page"
        error={startPageError}
        onChange={onStartPageChange}
        value={startPage}
      />
      to
      <PageNumberInput
        id="toPage"
        label="Ending page"
        error={endPageError}
        onChange={onEndPageChange}
        value={endPage}
        min={startPage}
        max={maxPage}
      />
      <ToolTipIcon title={HelpText} />
    </Container>
  );
};

export default PageRangeSelector;
