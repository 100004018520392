import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Tooltip } from '@mui/material';
import { Button } from '@mui/material';

export const ToolTipIcon = ({title}) => (
    <span>
        <Tooltip title={title} >
            <a><HelpOutlineIcon color="success" sx={{verticalAlign: "middle", padding: "5px"}}/></a>
        </Tooltip>
    </span>
);