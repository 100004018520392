import { useState, useEffect } from "react";
import useFetchDownload from "../services/useFetchDownload";
import { FileUploadFlow } from "./FileUploadFlow";
import { useNavigate } from "react-router-dom";
import useUserName from "../services/useUserName";
import useTrackUserEvent from "../services/useTrackUserEvent";

const Step3 = ({ setStep3Download }) => {
  const [processing, setProcessing] = useState(false);
  const [complete, setComplete] = useState(false);
  const [file, setFile] = useState();
  const [error, setError] = useState();
  const fetchDownload = useFetchDownload();

  const navigate = useNavigate();

  const user = useUserName();

  const trackUserEvent = useTrackUserEvent();

  useEffect(() => (document.title = "Podium Prepper - Step 3: Upload Q&A"), []);

  const instructionText = "Step 3: Upload Q&A Document to Generate Flashcards";

  const onConfirm = (file) => {
    setProcessing(true);

    var formdata = new FormData();
    formdata.append("file", file);
    formdata.append("user", user);

    trackUserEvent("Submitted file for Step 3", { file: file.name });

    fetchDownload(formdata, process.env.REACT_APP_API_ENDPOINT_STEP_3)
      .then((download) => {
        setStep3Download(download);
        setComplete(true);
      })
      .catch((error) => {
        setProcessing(false);
        setFile(null);
        setError(error.message);
        console.log("error", error);
      });
  };

  return (
    <FileUploadFlow
      InstructionalText={instructionText}
      ConfirmAction={onConfirm}
      FileTypes={[".docx"]}
      onComplete={() => {
        navigate("/complete");
      }}
      onCancel={() => {
        trackUserEvent("Cancelled file selection for Step 3");
      }}
      {...{ file, setFile, processing, complete, error, setError }}
    />
  );
};

export default Step3;
