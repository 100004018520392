import TrackedLink from "./TrackedLink";

const ManualDownloadLink = ({ download }) => {
  return (
    <p>
      The output file should have downloaded automatically. If it didn't,{" "}
      <TrackedLink
        href={download.url}
        download={download.filename}
        trackedLinkName="manual download link"
        trackedEventProps={{ file: download.filename }}
      >
        click here to download manually.
      </TrackedLink>{" "}
      <br />
      Or, if you wish to specify the download location, right click the link and
      choose “Save Link as...”
    </p>
  );
};

export default ManualDownloadLink;
