import { appInsights } from "./AppInsights";

export const useTrackUserEvent = () => {
  const trackUserEvent = (name, customProperties = null) => {
    if (appInsights) appInsights.trackEvent({ name }, customProperties);
  };

  return trackUserEvent;
};

export default useTrackUserEvent;
