import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Link } from "react-router-dom";
import styled from "styled-components";
import ManualDownloadLink from "./ManualDownloadLink";
import { useEffect } from "react";

const StyledLink = styled(Link)`
  text-decoration: none;
  & :hover {
    text-decoration: underline;
  }
`;

const Complete = ({ step3Download }) => {
  useEffect(() => (document.title = "Podium Prepper - Complete"), []);

  return (
    <div style={{ textAlign: "center" }}>
      <CheckCircleIcon sx={{ color: "#7a9c49", fontSize: 90 }} />
      <h2>Processing Complete!</h2>
      <ManualDownloadLink download={step3Download} />

      <p>You may use the Step links above to process more files.</p>
    </div>
  );
};

export default Complete;
