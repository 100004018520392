import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { createBrowserHistory } from "history";

const browserHistory = createBrowserHistory({ basename: "" });

const twelveHoursInMs = 1000 * 60 * 60 * 12;

const instrumentationKey =
  process.env.REACT_APP_APPINSIGHTS_INSTRUMENTATION_KEY;

if (!instrumentationKey)
  console.warn(
    "Application Insights Instrumentation Key not set, tracking will not be sent."
  );

export const reactPlugin = instrumentationKey && new ReactPlugin();
export const appInsights =
  instrumentationKey &&
  new ApplicationInsights({
    config: {
      instrumentationKey: process.env.REACT_APP_APPINSIGHTS_INSTRUMENTATION_KEY,
      extensions: [reactPlugin],
      enableAutoRouteTracking: true,
      extensionConfig: {
        [reactPlugin.identifier]: { history: browserHistory },
      },
      // Expiration is the max amount of time before a new session will be logged. Renewal is the time period in which
      // telemetry must be submitted to keep the current session alive. We are interested in tracking whether users
      // return to the same page in order to proceed to the flashcard formatting step after editing the word document output.
      // Editing is likely to take several hours minimum, so we set the renewal the same as the maximum, which is set to
      // the length of a long work day.
      sessionExpirationMs: twelveHoursInMs,
      sessionRenewalMs: twelveHoursInMs,
    },
  });

if (appInsights) appInsights.loadAppInsights();

export const setAppInsightsUser = (user) => {
  if (appInsights) appInsights.setAuthenticatedUserContext(user, null, true);
};
