import { Routes, Route, Navigate } from "react-router";
import { StepSelector } from "./StepSelector";
import Header from "./Header";
import Footer from "./Footer";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import HelpButton from "./HelpButton";
import Complete from "./Complete";
import { useState } from "react";
import { Stack } from "@mui/material";

const Layout = (props) => {
  const [step1Download, setStep1Download] = useState();
  const [step3Download, setStep3Download] = useState();

  return (
    <div
      style={{
        display: "grid",
        gridTemplateRows: "auto 1fr auto",
        minHeight: "100vh",
        margin: 0,
        paddingLeft: 10,
        paddingRight: 10,
      }}
    >
      <Header />
      <div>
        <Stack direction="row" style={{ marginBottom: 20 }}>
          <StepSelector />
          <HelpButton sx={{ marginRight: 0, marginLeft: "auto" }} />
        </Stack>
        <Routes>
          <Route
            exact
            path="/step1"
            element={<Step1 setStep1Download={setStep1Download} />}
          />
          <Route
            exact
            path="/step2"
            element={<Step2 step1Download={step1Download} />}
          />
          <Route
            exact
            path="/step3"
            element={<Step3 setStep3Download={setStep3Download} />}
          />
          <Route
            exact
            path="/complete"
            element={
              step3Download ? (
                <Complete step3Download={step3Download} />
              ) : (
                <Navigate replace to="/step1" />
              )
            }
          />
          <Route exact path="/" element={<Navigate replace to="/step1" />} />
          <Route path="*" element={<Navigate replace to="/step1" />} />
        </Routes>
      </div>
      <Footer />
    </div>
  );
};

export default Layout;
