import styled from "styled-components";
import portalLogo from "../assets/thePortal.png";
import podiumPrepperLogo from "../assets/podium-prepper-logo.png";
import TrackedLink from "./TrackedLink";

const BannerSection = styled.div`
  max-width: 50%;
`;

const Header = () => {
  return (
    <div style={{ marginBottom: 10, marginTop: 15 }}>
      <div
        style={{ margin: 5, justifyContent: "space-between", display: "flex" }}
      >
        <BannerSection>
          <TrackedLink
            href="https://theportal.orrick.com"
            trackedLinkName="link to The Portal"
          >
            <img src={portalLogo} style={{ height: 50, marginTop: 25 }} />
          </TrackedLink>
        </BannerSection>
        <BannerSection>
          <img
            src={podiumPrepperLogo}
            style={{ height: 100, marginBottom: 10 }}
          />
        </BannerSection>
      </div>
      <hr />
    </div>
  );
};

export default Header;
