import editIcon from "../assets/edit_with_word_icon.png";
import { Link } from "react-router-dom";
import styled from "styled-components";
import ManualDownloadLink from "./ManualDownloadLink";
import { useEffect } from "react";

const StyledLink = styled(Link)`
  text-decoration: none;
  & :hover {
    text-decoration: underline;
  }
`;

const Step2 = ({ step1Download }) => {
  useEffect(
    () => (document.title = "Podium Prepper - Step 2: Edit Draft Q&A"),
    []
  );

  return (
    <div style={{ textAlign: "center" }}>
      <img
        src={editIcon}
        alt="Edit in Word"
        title="edit in word"
        style={{ height: 90 }}
      />
      <h2>Step 2: Manually edit the output file in Word</h2>
      {step1Download ? (
        <ManualDownloadLink download={step1Download} />
      ) : (
        <p>
          Start with{" "}
          <StyledLink to="/step1">
            Step 1: Upload Brief to Extract Answers
          </StyledLink>{" "}
          to get an output file.
        </p>
      )}
      <p>
        When you are finished editing the document to be your desired question
        and answer pairs, make sure to save, <br />
        then proceed to{" "}
        <StyledLink to="/step3">Step 3: Upload Q{"&"}A</StyledLink> to format
        the document into printable flashcards.
      </p>
    </div>
  );
};

export default Step2;
