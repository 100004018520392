import { LinearProgress } from "@mui/material";
import { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

// This equation is calibrated to return ~70% when the full estimated time has elapsed
// and ~95% when double the estimated time has elapsed. It is roughly linear up to the estimated
// time and progressively slows down from there to become asymptotic such that it will never return
// 100%
const asymptoticPercent = (timeElapsed, estimatedTime) => {
  let doubledEstimate = 2 * estimatedTime;
  let timeRemaining = doubledEstimate - timeElapsed;
  let linearComponent = timeElapsed / doubledEstimate;
  let linearWeight = Math.max(timeRemaining / doubledEstimate, 0);

  let asymptoticComponent = timeElapsed / (timeElapsed + estimatedTime / 20);
  let asymptoticWeight = Math.min(timeElapsed / doubledEstimate, 1);

  let estimatedPercent =
    100 *
    (linearComponent * linearWeight + asymptoticComponent * asymptoticWeight);
  return estimatedPercent;
};

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.floor(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

const TimeEstimatingProgressBar = ({
  estimatedTime,
  complete,
  onComplete,
  ...props
}) => {
  const [elapsed, setElapsed] = useState(0);

  useEffect(() => {
    if (complete) {
      setTimeout(() => {
        onComplete();
      }, 300);
    }
  }, [complete]);

  useEffect(() => {
    const interval = setInterval(() => {
      setElapsed((x) => x + 0.1);
    }, 100);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    setElapsed(0);
  }, [estimatedTime]);

  return (
    <LinearProgressWithLabel
      value={complete ? 100 : asymptoticPercent(elapsed, estimatedTime)}
    />
  );
};

export default TimeEstimatingProgressBar;
