import { useMsal } from "@azure/msal-react";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { parse } from "content-disposition-header";
import { useState } from "react";

export const useFetchDownload = () => {
  const { instance, accounts } = useMsal();

  function getAccessToken() {
    let tokenRequest = {
      scopes: [`${process.env.REACT_APP_API_AUTH_AUTHORITY}/.default`],
    };

    return instance.acquireTokenSilent(tokenRequest).then((response) => {
      instance.setActiveAccount(response.account);
      return response.accessToken;
    });
  }

  function fetchDownload(formData, endpoint) {
    return getAccessToken().then((token) => {
      let headers = new Headers();
      headers.append("Authorization", `Bearer ${token}`);

      let requestOptions = {
        method: "POST",
        body: formData,
        mode: "cors",
        withCredentials: true,
        credentials: "include",
        headers,
      };

      let request = new Request(endpoint, requestOptions);

      return fetch(request)
        .then((response) => {
          if (!response.ok) throw Error(response.statusText);
          let filename = "";
          var contentDisposition = response.headers.get("Content-Disposition");
          if (contentDisposition)
            filename = parse(contentDisposition).parameters["filename"];
          return response.blob().then((blob) => {
            return {
              blob,
              filename,
            };
          });
        })
        .then(({ blob, filename }) => {
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", filename);

          // Append to html link element page
          document.body.appendChild(link);

          // Start download
          link.click();

          // Clean up and remove the link
          link.parentNode.removeChild(link);

          return { url, filename };
        });
    });
  }

  return fetchDownload;
};

export default useFetchDownload;
