import useTrackUserEvent from "../services/useTrackUserEvent";

const TrackedLink = ({
  trackedLinkName,
  trackedEventProps,
  children,
  ...props
}) => {
  const trackUserEvent = useTrackUserEvent();
  const leftClickMessage = `Clicked ${trackedLinkName}`;
  const rightClickMessage = `Right clicked ${trackedLinkName}`;

  return (
    <a
      {...props}
      onClick={() => {
        trackUserEvent(leftClickMessage, trackedEventProps);
      }}
      onContextMenu={() => {
        trackUserEvent(rightClickMessage, trackedEventProps);
      }}
    >
      {children}
    </a>
  );
};

export default TrackedLink;
