import "./App.css";
import AuthWrapper from "./components/AuthWrapper";
import Layout from "./components/Layout";
import { BrowserRouter } from "react-router-dom";
import withTracking from "./services/withTracking";
import { setAppInsightsUser } from "./services/AppInsights";
import useTrackUserEvent from "./services/useTrackUserEvent";

function App() {
  const trackUserEvent = useTrackUserEvent();

  const onAuthenticated = (account, loginType) => {
    setAppInsightsUser(account.userName);
    trackUserEvent("Login succeeded", { loginType });
  };

  const onLoginRedirect = () => {
    trackUserEvent("Attempting login via redirect");
  };

  return (
    <div className="App">
      <AuthWrapper
        onAuthenticated={onAuthenticated}
        onLoginRedirect={onLoginRedirect}
      >
        <BrowserRouter>
          <Layout />
        </BrowserRouter>
      </AuthWrapper>
    </div>
  );
}

export default withTracking(App);
