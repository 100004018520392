import { reactPlugin } from "./AppInsights";
import { withAITracking } from "@microsoft/applicationinsights-react-js";

function withTracking(component) {
  if (!reactPlugin) return component;

  return withAITracking(reactPlugin, component);
}

export default withTracking;
