import React from "react";
import Dropzone from "react-dropzone";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import FileSelectedIcon from "@mui/icons-material/Task";
import PropTypes from "prop-types";
import "./common-style.css";
import styled from "styled-components";
import Button from "@mui/material/Button";
import { Alert } from "@mui/material";
import ProgressBar from "./TimeEstimatingProgressBar";

const Container = styled.div`
  background: ${(props) =>
    props.isDragActive && !props.isDragReject ? "#ebe8e4" : "#ffffff"};
  border-width: ${(props) => (props.file == null ? "5px" : "0px")};
  border-style: dashed;
  border-color: ${(props) => (props.isDragReject ? "#ff0000" : "#7A9C49")};
  border-radius: 31px;
  text-align: center;
  width: 90%;
  margin: 0 auto;
  padding: 30px;
  & .icon {
    font-size: 90px;
  }
  & .upload-icon {
    color: ${(props) =>
      props.isDragReject
        ? "#ff0000"
        : props.isDragActive
        ? "#7a9c49"
        : "#717271"};
  }
  ${(props) =>
    props.file == null &&
    !props.isDragReject &&
    `
            &:hover {
                cursor: pointer;
            }
            &:hover a {
                text-decoration: underline;
                color: #1fa5ff
            }`}
`;

const InputContent = (InstructionalText, FileTypes, getInputProps) => {
  const fileTypesText = (fileTypes) => {
    let result = "";
    for (let i = 0; i < fileTypes.length; i++) {
      if (i === fileTypes.length - 1 && i !== 0) result += "or ";
      result += fileTypes[i];
      if (i !== fileTypes.length - 1) result += ", ";
    }

    return result;
  };

  return (
    <div>
      <input {...getInputProps()} />
      <UploadFileIcon className="icon upload-icon" />
      {/* // sx={{ fontSize: 90, color: "#717271" }} /> */}
      <br />
      <h2>
        <a>{InstructionalText}</a>
      </h2>
      <p>
        Drag and drop, or click to upload
        <br />
        {fileTypesText(FileTypes)}
      </p>
    </div>
  );
};

const ConfirmContentLayout = (
  file,
  clearFile,
  children,
  onConfirm,
  ConfirmEnabled
) => {
  return (
    <div>
      <FileSelectedIcon sx={{ fontSize: "100px", color: "#7A9C49" }} />
      <h2>Selected: {file.name}</h2>
      {children}
      <Button
        variant="contained"
        color="success"
        onClick={() => onConfirm(file)}
        disabled={!ConfirmEnabled}
      >
        Process
      </Button>
      <br />
      <Button variant="text" color="error" onClick={clearFile}>
        Cancel
      </Button>
    </div>
  );
};

export const FileUploadFlow = ({
  InstructionalText,
  FileTypes,
  ConfirmContent,
  ConfirmAction,
  ConfirmEnabled,
  onCancel,
  processing,
  complete,
  onComplete,
  error,
  setError,
  file,
  setFile,
}) => {
  const onDropAccepted = (acceptedFiles) => {
    setFile(acceptedFiles.length === 0 ? null : acceptedFiles[0]);
  };

  const onDropRejected = (rejectedFiles) => {
    if (rejectedFiles.length > 1)
      setError("You can only upload one file at a time.");
    else setError("Invalid File Type: " + rejectedFiles[0].file.name);
  };

  const mimeTypes = {
    ".pdf": "application/pdf",
    ".docx":
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ".doc": "application/msword",
  };

  const mapToMimeTypes = (fileTypes) => {
    let mapped = fileTypes.map((x) => mimeTypes[x] ?? x);
    return [...fileTypes, ...mapped];
  };

  if (processing)
    return (
      <Container file={file}>
        <h2>Processing...</h2>
        <ProgressBar
          size={70}
          estimatedTime={processing.estimatedTime ?? 10}
          {...{ processing, complete, onComplete }}
        />
        <p>Please be patient as this may take a couple minutes.</p>
      </Container>
    );

  return (
    <Dropzone
      maxFiles={1}
      accept={mapToMimeTypes(FileTypes)}
      onDropAccepted={onDropAccepted}
      onDropRejected={onDropRejected}
      noClick={file != null}
    >
      {({ getRootProps, getInputProps, isDragActive, isDragReject }) => {
        const clearFile = () => {
          setFile(null);
          if (onCancel) onCancel();
        };

        const defaultConfirm = (selectedFile) => {
          console.log(selectedFile);
        };

        return (
          <>
            {error ? (
              <Alert
                severity="error"
                sx={{ width: "90%", margin: "20px auto", padding: "10px 40px" }}
                onClose={() => setError(null)}
              >
                <strong>Error:</strong> {error}
              </Alert>
            ) : null}
            <Container {...getRootProps({ isDragActive, isDragReject, file })}>
              {file == null
                ? InputContent(InstructionalText, FileTypes, getInputProps)
                : ConfirmContentLayout(
                    file,
                    clearFile,
                    ConfirmContent,
                    ConfirmAction ?? defaultConfirm,
                    ConfirmEnabled ?? true
                  )}
            </Container>
          </>
        );
      }}
    </Dropzone>
  );
};

FileUploadFlow.propTypes = {
  InstructionalText: PropTypes.string,
  FileTypes: PropTypes.arrayOf(PropTypes.string),
};

export default FileUploadFlow;
