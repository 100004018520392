import React, { useEffect, useState } from "react";
import {
  AuthenticatedTemplate,
  MsalProvider,
  useMsalAuthentication,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import {
  PublicClientApplication,
  InteractionType,
  InteractionRequiredAuthError,
} from "@azure/msal-browser";

const configuration = {
  auth: {
    clientId: process.env.REACT_APP_AUTH_CLIENT_ID,
    authority: process.env.REACT_APP_AUTH_AUTHORITY,
  },
};

const pca = new PublicClientApplication(configuration);

const AuthWrapperView = ({ children, onLoginRedirect, onAuthenticated }) => {
  const request = {};

  const { login, result, error } = useMsalAuthentication(
    InteractionType.Silent,
    request
  );

  const [errorMessage, setErrorMessage] = useState();
  const [loading, setLoading] = useState(true);
  const { instance } = useMsal();

  useEffect(() => {
    if (error) {
      console.log(error);
    }
    if (error instanceof InteractionRequiredAuthError) {
      sessionStorage.setItem("loginType", "Redirect");
      if (onLoginRedirect) onLoginRedirect();
      login(InteractionType.Redirect, request)
        .catch((error) => {
          console.log(error);
          login(InteractionType.Popup, request).catch((error) => {
            setErrorMessage(error.errorMessage);
          });
        })
        .finally(() => setLoading(false));
    }
  }, [error]);

  useEffect(() => {
    if (result) {
      setLoading(false);
      instance.setActiveAccount(result.account);
      let loginType = sessionStorage.getItem("loginType") ?? "Silent";
      sessionStorage.removeItem("loginType");
      if (onAuthenticated) onAuthenticated(result.account, loginType);
    }
  }, [result]);

  return (
    <>
      <AuthenticatedTemplate>{children}</AuthenticatedTemplate>
      {result ? null : (
        <UnauthenticatedTemplate>
          <p>
            {loading ? "Loading..." : error ? errorMessage : "Unauthorized"}
          </p>
        </UnauthenticatedTemplate>
      )}
    </>
  );
};

const AuthWrapper = ({ onAuthenticated, onLoginRedirect, children }) => {
  return (
    <MsalProvider instance={pca}>
      <AuthWrapperView
        onAuthenticated={onAuthenticated}
        onLoginRedirect={onLoginRedirect}
      >
        {children}
      </AuthWrapperView>
    </MsalProvider>
  );
};

export default AuthWrapper;
