import Breadcrumbs from "@mui/material/Breadcrumbs";
import "./common-style.css";
import { Link, useMatch, useResolvedPath } from "react-router-dom";
import styled from "styled-components";
import useTrackUserEvent from "../services/useTrackUserEvent";

const StyledLink = styled(Link)`
  text-decoration: none;
  & :hover {
    text-decoration: underline;
  }
`;

function CustomLink({ children, to, trackUserEvent }) {
  let resolved = useResolvedPath(to);
  let match = useMatch({ path: resolved.pathname, end: true });
  return (
    <div>
      <h2>
        {match ? (
          <span>{children}</span>
        ) : (
          <StyledLink
            to={to}
            onClick={() =>
              trackUserEvent(`Clicked header link to navigate to ${to}`)
            }
          >
            {children}
          </StyledLink>
        )}
      </h2>
    </div>
  );
}

export const StepSelector = ({ selectedStep }) => {
  const Step1Label = "Upload Brief";
  const Step2Label = "Edit Draft Q&A";
  const Step3Label = "Upload Q&A";

  const trackUserEvent = useTrackUserEvent();

  return (
    <Breadcrumbs separator=">>">
      <CustomLink to="step1" trackUserEvent={trackUserEvent}>
        {Step1Label}
      </CustomLink>
      <CustomLink to="step2" trackUserEvent={trackUserEvent}>
        {Step2Label}
      </CustomLink>
      <CustomLink to="step3" trackUserEvent={trackUserEvent}>
        {Step3Label}
      </CustomLink>
    </Breadcrumbs>
  );
};
