import {
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import styled from "styled-components";
import StyledAccordion from "./StyledAccordion";

const Container = styled.div`
  text-align: center;
  width: 100%;
`;

const BootstrapDialogTitle = ({ children, onClose, ...other }) => {
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const ToolExplanationDialog = ({ open, handleClose }) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <BootstrapDialogTitle onClose={handleClose} id="alert-dialog-title">
        Tool Explanation
      </BootstrapDialogTitle>
      <DialogContent>
        <StyledAccordion startExpanded title="SUMMARY">
          <p>
            Podium Prepper saves you time when creating flashcards for oral
            argument.
          </p>
          <p>
            It extracts sentences and related citations from briefs and places
            them in a table in Microsoft Word for you to edit.
          </p>
          <p>
            The processing algorithm does not generate perfect results but using
            its output as a starting point should save significant time compared
            to manually copy/pasting from the brief.
          </p>
        </StyledAccordion>
        <StyledAccordion title="THE PROCESS">
          <p>
            <strong>Step 1:</strong> Upload your brief and the tool will extract
            each sentence and related citation. The sentences are placed as
            “answers” in a two-column table for you to edit and add questions.
          </p>
          <p>
            <strong>Step 2:</strong> Edit the document in Microsoft Word as you
            would when creating flashcards for oral argument prep. This may
            include deleting irrelevant rows, adding questions, and editing the
            answers.
          </p>
          <p>
            <strong>Step 3:</strong> Once editing is complete, save and upload
            your document to generate formatted and printable flashcards.
          </p>
        </StyledAccordion>
        <StyledAccordion title="TIPS AND TRICKS FOR EDITING THE OUTPUT">
          <p>
            Clean up the output by using Word's table editing features to
            delete, merge, and re-order rows.
          </p>
          <p>
            Footnotes will appear as rows in the output in the location where
            they appeared. If the main text of a page ends in the middle of a
            sentence and then there are footnotes, that sentence will be broken
            apart into separate rows, with rows containing the footnote text in
            between.
          </p>
          <p>
            To fix, you can delete the footnote rows and merge the rows
            containing the main text sentence. Alternatively, you might want to
            include the footnotes as part of the answer text for the sentences
            with which they were associated, in which case you could re-order
            the rows and then merge them
          </p>
        </StyledAccordion>
      </DialogContent>
      <DialogActions>
        <Container>
          <Button onClick={handleClose} autoFocus>
            OK
          </Button>
        </Container>
      </DialogActions>
    </Dialog>
  );
};

export default ToolExplanationDialog;
