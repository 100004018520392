import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { IconButton } from "@mui/material";
import { useState } from "react";
import useTrackUserEvent from "../services/useTrackUserEvent";
import ToolExplanationDialog from "./ToolExplanationDialog";

const dismissedFirstTimeHelpLocalStorageKey = "dismissedFirstTimeHelp";

const HelpButton = (props) => {
  const trackUserEvent = useTrackUserEvent();
  const firstTime =
    localStorage.getItem(dismissedFirstTimeHelpLocalStorageKey) === null
      ? true
      : false;

  const [open, setOpen] = useState(firstTime);

  const handleClickOpen = () => {
    setOpen(true);
    trackUserEvent("Opened Tool Explanation (?)");
  };

  const handleClose = () => {
    setOpen(false);
    if (firstTime) {
      localStorage.setItem(dismissedFirstTimeHelpLocalStorageKey, "true");
    }
    trackUserEvent("Closed Tool Explanation (?)", { firstTime });
  };
  return (
    <>
      <IconButton {...props} size="small" onClick={handleClickOpen}>
        <HelpOutlineIcon sx={{ fontSize: 50, color: "#7a9c49" }} />
      </IconButton>
      <ToolExplanationDialog open={open} handleClose={handleClose} />
    </>
  );
};

export default HelpButton;
